/* Layout principal */
.layout {
    display: flex;
    height: 100vh;
    font-family: 'Arial', sans-serif;
}

/* Header do sidebar */
#h2 {
    color: black;
}

/* Sidebar */
.sidebar {
    width: 250px;
    background: #2c3e50;
    color: white;
    display: flex;
    flex-direction: column;
}

/* Cabeçalho do sidebar */
.sidebar-header {
    padding: 20px;
    text-align: center;
    background: #ffffff;
}

/* Logo no sidebar */
.logo-sidebar {
    width: 120px;
    margin-bottom: 5px;
}

/* Navegação do sidebar */
.sidebar-nav {
    flex: 1;
    display: flex;
    flex-direction: column;
}

/* Links de navegação do sidebar */
.sidebar-nav a {
    padding: 15px 20px;
    color: white;
    text-decoration: none;
    border-bottom: 1px solid #34495e;
}

/* Hover nos links do sidebar */
.sidebar-nav a:hover {
    background: #1abc9c;
}

/* Botão de logout */
.logout-button {
    padding: 15px 20px;
    color: white;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    border-bottom: 1px solid #34495e;
    width: 100%;
}

.logout-button:hover {
    background: #e74c3c;
}

/* Conteúdo principal */
.main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

/* Cabeçalho principal */
.main-header {
    background: #ecf0f1;
    padding: 20px;
    border-bottom: 1px solid #bdc3c7;
}

/* Conteúdo */
.content {
    padding: 20px;
    flex: 1;
    overflow-y: auto;
    background: #ecf0f1;
}

/* Itens de notificação */
.notification-item {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
}

/* Cabeçalho da notificação */
.notification-header {
    font-size: 1.2em;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
}

/* Corpo da notificação */
.notification-body {
    font-size: 1em;
}

/* Estilos no corpo da notificação */
.notification-body strong {
    display: block;
    margin-top: 10px;
    color: #333;
}

.notification-body ul {
    list-style-type: none;
    padding: 0;
}

.notification-body ul li {
    background: #f9f9f9;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
}

.notification-body a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.notification-body a:hover {
    text-decoration: underline;
}

/* Container do calendário */
.calendar-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsividade */
@media (max-width: 768px) {
    .layout {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        height: auto;
        background-color: #14497e;
        flex-direction: row;
        justify-content: space-between;
    }

    .sidebar-header {
        display: none;
        text-align: center;
        margin-bottom: 10px;
    
    }

    .sidebar-nav {
        flex-direction: row;
        overflow-x: auto;
    }



    
    .sidebar-nav a {
        flex: 1;
        text-align: center;
        border-bottom: none;
        border-right: 1px solid #34495e;
    }

    .sidebar-nav a:last-child {
        border-right: none;
    }

    .main-content {
        margin-top: 20px;
    }

    .main-header {
        padding: 10px;
    }

    .content {
        padding: 10px;
    }

    .notification-item {
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .notification-header {
        font-size: 1em;
    }

    .notification-body {
        font-size: 0.9em;
    }
}
