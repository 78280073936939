/* Clients.css */
.clients-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.clients-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.clients-list {
    list-style-type: none;
    padding: 0;
}

.clients-list li {
    background: white;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    transition: transform 0.3s;
}

.clients-list li:hover {
    transform: translateY(-5px);
}

.clients-list h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #007bff;
}

.clients-list p {
    font-size: 1em;
    margin-bottom: 10px;
    color: #555;
}

.clients-list h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #333;
}

.clients-list ul {
    list-style-type: none;
    padding: 0;
}

.clients-list ul li {
    background: #f9f9f9;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    color: #555;
}
.filter-input {
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
}

