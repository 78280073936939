body {
    font-family: 'Arial', sans-serif;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
}

.container {
    width: 80%;
    margin: 20px auto;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.process-list {
    list-style-type: none;
    padding: 0;
}

.process-item {
    background-color: #f9f9f9;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.process-item div {
    margin-bottom: 10px;
}

.process-item strong {
    display: inline-block;
    width: 150px;
    color: #007bff;
}

.process-item ul {
    list-style-type: none;
    padding: 0;
}

.process-item li {
    background-color: #fff;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ddd;
}
