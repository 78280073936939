.modal-compromisso .modal-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}

.modal-compromisso .modal-body {
    max-height: 60vh;
    overflow-y: auto;
}

.modal-compromisso .search-container {
    position: relative;
}

.modal-compromisso .filter-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 8px;
}

.modal-compromisso .clients-dropdown {
    position: absolute;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    z-index: 1000;
}

.modal-compromisso .clients-dropdown li {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #ced4da;
}

.modal-compromisso .clients-dropdown li:hover {
    background-color: #f1f1f1;
}

.modal-compromisso .selected-client {
    padding: 8px;
    background-color: #e9ecef;
    border-radius: 4px;
    margin-bottom: 8px;
    font-weight: bold;
}
