.dashboard-container {
    display: flex;
    height: 100vh;
    background-color: #f4f7fa;
}




.dashboard-main {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
}

.dashboard-header {
    margin-bottom: 20px;
}

.dashboard-header h1 {
    margin: 0;
    font-size: 24px;
    color: #333;
}

.dashboard-header p {
    color: #777;
    font-size: 14px;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    text-align: center;
}

.card:hover {
    transform: translateY(-5px);
}

.card-icon {
    margin-bottom: 15px;
    color: #007bff;
}

.card h3 {
    margin: 0 0 10px;
    color: #333;
    font-size: 18px;
}

.card p {
    margin: 0 0 10px;
    color: #555;
    font-size: 14px;
}

.card a {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
}

.card a:hover {
    text-decoration: underline;
}
