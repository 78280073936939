.compromissos-container {
    padding: 20px;
}

.compromissos-list {
    list-style-type: none;
    padding: 0;
}

.compromisso-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
