/* Estilos de Login */
.login-container {
    width: 300px;
    margin: 100px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.login-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #007bff;
}

.login-container form {
    display: flex;
    flex-direction: column;
}

.login-container input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.login-container button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.login-container button:hover {
    background-color: #0056b3;
}

.login-logo {
    width: 150px;
    margin-bottom: 20px;
}






/* Estilos Globais */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f0f2f5;
    margin: 0;
    padding: 0;
    color: #333;
}

.container {
    width: 80%;
    margin: 20px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.tabs {
    margin: 20px 0;
}

.tab-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    border-bottom: 2px solid #ddd;
}

.tab-button {
    background-color: transparent;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    color: #007bff;
    transition: color 0.3s;
}

.tab-button.active {
    color: #0056b3;
    border-bottom: 2px solid #007bff;
}

.tab-button:hover {
    color: #0056b3;
}

.tab-content {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.process-list, .notification-list {
    list-style-type: none;
    padding: 0;
}

.process-item, .notification-item {
    background-color: #f9f9f9;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
    cursor: pointer;
    transition: box-shadow 0.3s;
}

.process-item:hover, .notification-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.process-item div, .notification-item div {
    margin-bottom: 10px;
}

.process-item strong, .notification-item strong {
    display: inline-block;
    width: 150px;
    color: #007bff;
}

.process-item ul, .notification-item ul {
    list-style-type: none;
    padding: 0;
}

.process-item li, .notification-item li {
    background-color: #fff;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.date-filter {
    text-align: center;
    margin-bottom: 20px;
}

.date-filter label {
    font-size: 16px;
    color: #333;
    margin-right: 10px;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 50%;
    max-width: 500px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

/* Estilos de Login */
.login-container {
    width: 300px;
    margin: 100px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #007bff;
}

.login-container form {
    display: flex;
    flex-direction: column;
}

.login-container input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.login-container button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.login-container button:hover {
    background-color: #0056b3;
}
.custom-calendar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.custom-calendar .day {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    user-select: none;
}

.custom-calendar .day.selected {
    background-color: #007bff;
    color: white;
    border-radius: 10px;
}

.custom-calendar button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 10px;
}
body {
    background: linear-gradient(to right, #74ebd5, #acb6e5);
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

h2 {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.date-filter {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.notification-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.notification-item {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s;
}

.notification-item:hover {
    transform: translateY(-5px);
}

.notification-header {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
}

.notification-body {
    font-size: 1em;
    color: #555;
}

.notification-body strong {
    display: block;
    margin-top: 10px;
    color: #333;
}

.notification-body ul {
    list-style-type: none;
    padding: 0;
}

.notification-body ul li {
    background: #f9f9f9;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
}

.notification-body a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.notification-body a:hover {
    text-decoration: underline;
}
.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}
.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 800px;
    max-height: 90%;
    overflow-y: auto;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.process-item {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.process-item:hover {
    background: #f9f9f9;
}

.process-header {
    font-size: 1.2em;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
}

.process-body {
    font-size: 1em;
}

.tabs {
    margin-top: 20px;
}

button {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

button:hover {
    background: #0056b3;
}
body {
    font-family: Arial, sans-serif;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

button {
    margin: 5px;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

.table {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.table th {
    background-color: #f2f2f2;
}

.modal-header, .modal-body, .modal-footer {
    padding: 15px;
}

.modal-header {
    border-bottom: 1px solid #e5e5e5;
}

.modal-footer {
    border-top: 1px solid #e5e5e5;
}

.form-group {
    margin-bottom: 15px;
}

.form-control {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

button {
    padding: 10px 15px;
    cursor: pointer;
}

button.primary {
    background-color: #007bff;
    color: white;
    border: none;
}

button.secondary {
    background-color: #6c757d;
    color: white;
    border: none;
}

button.primary:hover {
    background-color: #0056b3;
}

button.secondary:hover {
    background-color: #5a6268;
}

